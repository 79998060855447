import React from "react"
import "../../theme/style.scss"
import Header from "../Header"
import Footer from "../Footer"
import Cookie from "../cookie"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
      <Cookie />
    </>
  )
}

export default Layout
