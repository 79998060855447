import React, { useState, useEffect } from "react"
import { useCookies, withCookies } from "react-cookie"

function Cookie() {
  const [display, setDisplay] = useState(true)
  const [cookie, setCookies] = useCookies(["cookieConsent"])
  useEffect(() => {
    cookie.cookieConsent &&
      cookie.cookieConsent === "accepted" &&
      setDisplay(false)
  })
  return (
    <div id="cookie-modal" className={display ? "d-block" : "d-none"}>
      <div className="cookie-content">
        <div className="left">
          Cookies help us deliver our services. By using our services, you agree
          to our use of cookies.
          <a target="_blank" rel="noreferrer" href="https://www.shpock.com/en-gb/privacy-policy">
            {" "}
            Learn more
          </a>
        </div>
        <div className="right">
          <div
            id="cookie-modal-close"
            onClick={e => {
              setCookies("cookieConsent", "accepted", { path: "/" })
            }}
          >
            OK
          </div>
        </div>
      </div>
    </div>
  )
}
export default withCookies(Cookie)
