import React, { useState } from "react"
import css from "@emotion/css"
import logo from "../images/shpock-secondarylogo-green&dark.png"
import { Link  } from "gatsby"
import { Collapse } from "reactstrap"

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const Links = [
    { title: "Jobs", slug: "/" },
    { title: "Working @ Shpock", slug: "/work/" },
    { title: "This is Shpock", slug: "/shpock/" },
  ]

  return (
    <>
      <div
        css={css`
          transition: box-shadow 0.3s linear;
          background: #fff;
          &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
          }
        `}
      >
        <div
          className="container py-3 "
          css={css`
            display: flex;
            align-items: center;

            justify-content: center;
            padding-left: 15px;
            padding-right: 15px;
            /* @md */
            @media (min-width: 768px) {
              justify-content: space-between;
            }
          `}
        >
          <div className="d-block d-md-none">
            <div
              onClick={(e) => setIsMenuOpen((o) => !o)}
              className={`${isMenuOpen ? "open" : ""}`}
              css={css`
                width: 35px;
                height: 22px;
                position: absolute;
                left: 15px;
                top: 29px;

                &:hover {
                  cursor: pointer;
                }
                span {
                  position: absolute;
                  display: block;
                  width: 100%;
                  height: 2px;
                  left: 0;
                  background-color: #535353;
                  -webkit-transition: all 0.3s;
                  -moz-transition: all 0.3s;
                  transition: all 0.3s;
                  &:first-child {
                    top: 0;
                  }
                  &:nth-child(2) {
                    top: 50%;
                    margin-top: -1px;
                  }
                  &:last-child {
                    bottom: 0;
                  }
                }

                &.open span:first-child {
                  top: 50%;
                  -webkit-transform: rotate(-45deg);
                  -moz-transform: rotate(-45deg);
                  -ms-transform: rotate(-45deg);
                  -o-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                }
                &.open span:nth-child(2) {
                  opacity: 0;
                }
                &.open span:last-child {
                  top: 50%;
                  -webkit-transform: rotate(45deg);
                  -moz-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  -o-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
              `}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <Link to="/" className="logo" css={css``}>
          <div class="gatsby-image-wrapper" style={{position: "relative", overflow: "hidden", display: "inline-block", width: "183px", height: "35px"}}>
              <img src={logo} alt="Shpock logo" style={{position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", objectFit: "cover", objectPosition: "center center"}} />
          </div>
          </Link>
          <nav className="d-md-block d-none">
            {Links.map((item) => (
                <Link
                  key={item.slug}
                  activeClassName="active"
                  css={css`
                    padding: 0.4rem 0.2rem;
                    margin: 0 1rem;
                    color: var(--dark);
                    &.active,
                    &:hover {
                      color: var(--primary);
                      border-bottom: 2px solid var(--primary);
                      text-decoration: none;
                    }
                  `}
                  to={item.slug}
                >
                  {item.title}
                </Link>
              ))}
          </nav>
        </div>
        <Collapse isOpen={isMenuOpen}>
          {Links.map((item) => (
              <Link
                activeClassName="active"
                key={item.slug}
                css={css`
                  padding: 0.4rem 0.2rem;
                  margin: 0 15px;
                  color: var(--dark);
                  display: flex;
                  justify-content: center;
                  text-align: center;
                  &:last-child {
                    padding-bottom: 1rem;
                  }
                  &.active,
                  &:hover {
                    color: var(--primary);
                    border-bottom: 2px solid var(--primary);
                    text-decoration: none;
                  }
                `}
                onClick={() => {
                  setIsMenuOpen(false)
                }}
                to={item.slug}
              >
                {item.title}
              </Link>
            ))}
        </Collapse>
      </div>
    </>
  )
}
