import React from "react"
import linkedin from "../images/linkedin.png"
import instagram from "../images/instagram.png"
import twitter from "../images/twitter.png"
import gplay from "../images/store_googleplay.png"
import astore from "../images/store_apple.png"
import shparkleLogo from "../images/Icon.png"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <div id="footer">
      <ul className="social">
        <li className="linkedin">
          <a href="https://www.linkedin.com/company/2612019">
            <img alt="LinkedIn logo" height="30" src={linkedin} width="30" />
            <span>Join us on LinkedIn</span>
          </a>
        </li>
        <li className="instagram">
          <a href="https://www.instagram.com/shpocklife/">
            <img alt="Instagram logo" height="30" src={instagram} width="30" />
            <span>Follow us on Instagram</span>
          </a>
        </li>
      </ul>
      <div className="links">
        <div className="stores">
          <a href="https://itunes.apple.com/gb/app/shpock-classifieds-yard-sale/id557153158">
            <img height="30" src={astore} alt="Download for iOS" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.shpock.android&amp;hl=en">
            <img height="30" src={gplay} alt="Download for Android" />
          </a>
        </div>
        <ul className="footer-nav">
          <li>
            <a href="https://www.shpock.com/en-gb/contact">Contact</a>
          </li>
          <li>
            <a href="https://www.shpock.com/en-gb/help/web">Help Centre</a>
          </li>
          <li>
            <a href="https://www.shpock.com/en-gb/terms-conditions">
              Terms &amp; Conditions
            </a>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy</Link>
          </li>
          <li>
            <a href="https://news.shpock.com/">Press</a>
          </li>
          <li className="block">made with ♡ - © finderly GmbH</li>
        </ul>

        <div className="shpock-logo">
          <a href="https://www.shpock.com/en-gb">
          <img height="70" widht="70" src={shparkleLogo} alt="Shpock Shparkles Logo" />
          </a>
        </div>
      </div>
    </div>
  )
}
